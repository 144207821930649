import {type Token} from '@beer-abacus/core/models';

export default class Session{
    public static readonly accessTokenName = "accessToken";

    public static isTokenValid(token: Token): boolean{
        return !token || !token?.accessToken || !token?.expiresIn;
    }

    public static buildExpiryDate(expiresIn: number): Date{
        const dateNow = new Date();
        return new Date(dateNow.getTime() + (expiresIn * 1000))
    }
}